<template>
  <main class="userInfo">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div class="userInfo-block userInfo-user">
          <div class="userInfo-user-avatar">
            <b-avatar
              variant="primary"
              size="75px"
              :text="avatarText"
            ></b-avatar>
          </div>
          <div class="userInfo-user-content">
            <div class="userInfo-user-name">
              {{ userSelf.firstName }} {{ userSelf.lastName }}
            </div>
            <div class="userInfo-user-email">{{ userSelf.email }}</div>
          </div>
          <div class="userInfo-user-btns">
            <router-link class="btn btn-primary" :to="`/pages/password/setup`">
              <span class="material-icons">lock</span>Reset Password
            </router-link>

            <button class="btn btn-primary" @click.prevent="logout">
              <span class="material-icons">logout</span>
              Logout
            </button>
          </div>
        </div>
        <div
          class="userInfo-block userInfo-subscription"
          v-if="userSelf.subscription"
        >
          <div class="section-title subscription-title">
            Subscription Details
          </div>
          <div class="subscription-content">
            <div class="subscription-row">
              <div class="subscription-label">
                Company Name:
              </div>
              <div class="subscription-info">
                {{ userSelf.subscription.companyName }}
              </div>
            </div>
            <div class="subscription-row">
              <div class="subscription-label">
                Connections Count:
              </div>
              <div class="subscription-info">
                {{ userSelf.subscription.maxNumOfActiveTasks }}

                <span class="subscription-info-tips">
                  (Number of available connection under this subscription)
                </span>
              </div>
            </div>
            <div class="subscription-row">
              <div class="subscription-label">Period :</div>
              <div class="subscription-date">
                <UserInfoDateUI
                  validType="FROM"
                  :time="userSelf.subscription.validFrom"
                />
                <div class="subscription-date-arrow">
                  <span class="material-icons">east</span>
                </div>
                <UserInfoDateUI
                  validType="UNTIL"
                  :time="userSelf.subscription.validUntil"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import UserInfoDateUI from '../components/UserInfoDateUI.vue'

export default {
  name: 'UserInfo',
  components: { UserInfoDateUI },
  data() {
    return {
      breadcrumb: [
        {
          text: 'User Info',
          active: true
        }
      ]
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      userSelf: state => state.user.self
    }),
    avatarText() {
      if (!this.userSelf.firstName && !this.userSelf.lastName) return 'null'
      let firstLetter = this.userSelf.firstName.substr(0, 1)
      let lastLetter = this.userSelf.lastName.substr(0, 1)
      return firstLetter + lastLetter
    }
  },
  methods: {
    async logout() {
      let status = await this.$store.dispatch('logout')
      if (status === 200) {
        return this.$router.push({
          path: '/pages/login',
          query: { status: 'logout' }
        })
      } else {
        return this.$router.push('/pages/page404')
      }
    }
  }
}
</script>
